export const SliderImages = [
  {
    image: "Website.png",
    alt: "Website Pages",
  },
  {
    image: "To-doApp.png",
    alt: "To-Do App",
  },
  {
    image: "To-doAppCodeSnippet.png",
    alt: "To-doAppCodeSnippet",
  },
  {
    image: "VRGameScripts.png",
    alt: "VR Game scripts",
  },
];
